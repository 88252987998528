.radar__container{
    place-self: center center;
}

.chart__body{
    display: grid;
    place-content: center center;
    place-items: center center;
}
.chart__container{
    display: flex;
    margin: 10px;
    place-self: center center;
}

.chart__Box{
    place-self: center center;
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    width: fit-content;
    height: fit-content;
}

.chart__label{
    grid-column: 1;
    grid-row: 1;
    font-weight: bold;
    color:royalblue;
    font-size:medium;
}

.chart__input{
    grid-column: 1;
    grid-row: 2;
    width: fit-content;
    height: 200px;
    outline: 0;
    border: 0;
    font-weight: bolder;
    font-size:x-large;
    text-align: center;
}
.recomm__container{
    place-self: center center;
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    width: 600px;
    height: fit-content;
    padding: 2%;
}
.score__word{
    font-size: x-large;
    font-weight: 500;
    text-align: start;
    margin: 5px;
}
.word__definition{
    font-size: medium;
    margin: 5px;
    text-align: justify;
}
.word__ul{
    padding: 20px;
    margin: 5px;
    text-align: justify;
}
.word__li{
    font-style: italic;
}